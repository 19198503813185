import style from './styles/Main.module.scss'
import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";

function Main () {

    const [open, setOpen] = useState(false)

    return (
        <div className={style.bodymain}>
            <div className={style.moreblock} style={(open)?{display:'flex'}:{display:'none'}}>
                <div className={style.text}>Я не собираю твои персональные данные, но всегда читаю почту. Напиши мне</div>
                <div className={style.email}>logotypemonster@yandex.ru</div>
                <div className={style.exit}><i className="fa-regular fa-circle-xmark"  onClick={()=>setOpen(false)}/></div>
            </div>
            <div className={style.container}>
                <div className={style.title}>
                    <img src="/files/tooth.svg" className={style.tooth}/>
                    <div className={style.left}>LOGOTYPE</div>
                    <div className={style.right}>MONSTER</div>
                </div>
                <div className={style.engtitle}>
                    <div className={style.text}>
                        <div className={style.line}>LOGO</div>
                        <div className={style.linedown}>Design</div>
                    </div>
                    <div className={style.logo}>
                        <img src="/files/monster.svg" alt=""/>
                    </div>
                </div>
                <div className={style.rutitle}>СДЕЛАЮ ЛОГОТИП</div>
                <div className={style.more}>
                    <div className={style.btn} onClick={()=>setOpen(true)}>Подробнее</div>
                </div>
                <div className={style.portfolio}>
                    <div className={style.eng}>PORTFOLIO</div>
                    <div className={style.rus}>ПОРТФОЛИО</div>
                </div>
                <div className={style.logos}>
                    <img src="/files/SVG/man.svg"/>
                    <img src="/files/SVG/vdom1.svg"/>
                    <img src="/files/SVG/vdom2.svg"/>
                    <img src="/files/SVG/vdom3.svg"/>
                    <img src="/files/SVG/zagorod1.svg"/>
                    <img src="/files/SVG/zagorod2.svg"/>
                    <img src="/files/SVG/zagorod3.svg"/>
                    <img src="/files/SVG/blunt1.svg"/>
                    <img src="/files/SVG/blunt2.svg"/>
                    <img src="/files/SVG/blunt3_1.svg"/>
                    <img src="/files/SVG/seimo1.svg"/>
                    <img src="/files/SVG/seimo2.svg"/>
                    <img src="/files/SVG/seimo3.svg"/>
                    <img src="/files/SVG/nedra.svg"/>
                    <img src="/files/SVG/niceshit_1.svg"/>
                    <img src="/files/SVG/rabbit1.svg"/>
                    <img src="/files/SVG/port1.svg"/>
                    <img src="/files/SVG/port2.svg"/>
                    <img src="/files/SVG/port3.svg"/>
                    <img src="/files/SVG/calldragon2.svg"/>
                    <img src="/files/SVG/calldragon1.svg"/>
                    <img src="/files/SVG/schoose.svg"/>
                </div>
            </div>
        </div>
    )
}

export default Main